import { graphql } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import Meta from 'components/meta'
import Layout from 'components/layout'

import TextLoop from 'react-text-loop'

import { useJsonForm } from 'gatsby-tinacms-json'

let lang = 'de'

const EnIndex = ({ location, data }) => {
  const [node] = useJsonForm(data.dataJson, HomePageForm)
  const page = node.pages.home.de

  return (
    <Layout location={location}>
      <Helmet>
        <title>{page.site_title} - Reinhold Rößler</title>
      </Helmet>
      <header className="masthead" id="masthead">
        <div className="container d-flex align-items-center">
          <div className="mx-auto text-center">
            <div
              className="desktop-only"
              style={{ marginTop: `10em`, transition: `opacity 0.5s` }}
            >
              <div className="quote-loop">
                <h2 className="mt-2 quote-loop" style={{ color: `#35424b` }}>
                  <TextLoop noWrap={false} delay={1500} interval={10000}>
                    {page.quotes.map(item => (
                      <span>{item.text}</span>
                    ))}
                  </TextLoop>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section id="about" className="mobile-only about-section text-center">
        <div className="container mobile-only">
          <div className="about-mobile-intro">
            <h2 style={{ paddingBottom: `5rem` }}>{page.quotes[0].text}</h2>
            <h2 style={{ paddingBottom: `0rem` }}>{page.heading_1}</h2>
            <h3 style={{ paddingBottom: `5rem`, color: `#3d6589` }}>
              {page.heading_2}
            </h3>
          </div>
          <div className="about-facts">
            {page.list.map((item, index) => (
              <div className="about-fact">
                <p className="about-number">{index}</p>
                <p className="about-text">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section id="about" className="about-section text-center desktop-only">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 style={{ color: `#35424b` }}>{page.heading_1}</h2>
              <h3 className="mb-4" style={{ color: `#3d6589` }}>
                {page.heading_2}
              </h3>
              <ul style={{ textAlign: `left` }}>
                {page.list.map(item => (
                  <li>{item.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section
        id="footer1"
        className="footer1-section text-center desktop-only"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <p className="text-white-50">{page.footer}</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

const HomePageForm = {
  fields: [
    {
      label: 'Site Title',
      name: `rawJson.pages.home.${lang}.site_title`,
      component: 'text',
      required: true,
    },
    {
      component: 'group-list',
      name: `rawJson.pages.home.${lang}.quotes`,
      label: 'Quotes',
      required: true,
      fields: [
        {
          label: 'Text',
          name: 'text',
          component: 'text',
          required: true,
        },
      ],
      itemProps: item => ({
        label: item.text ? item.text : 'Quote',
        key: item,
      }),
    },
    {
      name: `rawJson.pages.home.${lang}.heading_1`,
      component: 'text',
      label: 'Heading 1',
      required: true,
    },
    {
      name: `rawJson.pages.home.${lang}.heading_2`,
      component: 'text',
      label: 'Heading 2',
      required: true,
    },
    {
      component: 'group-list',
      name: `rawJson.pages.home.${lang}.list`,
      label: 'Bullet Points',
      fields: [
        {
          label: 'Text',
          name: 'text',
          component: 'text',
          required: true,
        },
      ],
      itemProps: item => ({
        label: item.text ? item.text : 'Bullet Point',
        key: item,
      }),
    },
    {
      name: `rawJson.pages.home.${lang}.footer`,
      component: 'textarea',
      label: 'Footer',
      required: true,
    },
  ],
}

export const pageQuery = graphql`
  query {
    dataJson {
      pages {
        home {
          de {
            site_title
            footer
            heading_1
            heading_2
            list {
              text
            }
            quotes {
              text
            }
          }
        }
      }
      fileRelativePath
      rawJson
    }
  }
`

export default EnIndex
